import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"
import StyledIllustration from "src/components/features/StyledIllustration.jsx"

import padlock from "src/assets/img/graphics/padlock.svg"
import patientPortalLetters from "src/assets/img/screenshots/patient-portal-letters.png"
import patientPortalNewLetterFlow from "src/assets/video/patient-portal-new-letter.gif"

const Feature = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Patient portal"
        pathname="/features/patient-portal"
        description="Empower your patients with the Carebit Patient Portal, where they can download letters, test results and make bookings."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Patient Portal</h1>
              <p className="u-whiteTranslucent">
                The Portal is a revelation for patients. No longer do they have
                to phone or email your secretary for copies of test results, and
                no longer do they have to wait days for copies of new letters to
                be posted to them. Access is instant, secure and reliable, and
                positions you as a modern doctor.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Always there">
                        An archive of letters and test results for patients to
                        access
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Through the Portal, a patient has access to all the
                      letters and test results uploaded to their Carebit profile
                      by your clinic. That means there’s no need for them to
                      contact your clinic to ask for copies because they can
                      retrieve them at their convenience, 24/7.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - patients can view letters in the Patient Portal"
                      src={patientPortalLetters}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={5} md={12} className="SplitContentRow-graphic">
                    <img
                      alt="Carebit - patients recieve notification by email of new letters"
                      src={patientPortalNewLetterFlow}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col
                    lg={{ size: 6, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-text"
                  >
                    <Row>
                      <Heading title="A better patient experience">
                        Patients receive new letters instantly
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      When you write and send a new letter using Carebit
                      Compose, the patient receives an email notification
                      immediately. They can then simply enter their date of
                      birth to unlock the PDF letter. Often, this email is sent
                      as they are leaving your clinic - they’ll be impressed and
                      you’ll appear efficient and proactive.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/features/compose-letters">
                        <Button color="secondary" size="md">
                          Learn more about Carebit Compose &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col
                    lg={{ size: 6 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-text"
                  >
                    <Row>
                      <Heading title="Secure by design">
                        Security and encryption by default
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Security and privacy of patient data is of utmost
                      importance to us. After all, it’s your professional
                      reputation at stake too. That’s why patients can only
                      access the Portal over an encrypted SSL connection to
                      Carebit, and we additionally require patients to confirm
                      their date of birth and set a complex password before they
                      are able to log into their Carebit account to view their
                      data. If viewing letters when not logged in, the patient
                      accesses the letter via a random token that’s impossible
                      to guess in combination with their date of birth.
                    </p>
                    <p className="u-midGrey">
                      For added security, the links to the letter and test
                      result PDFs contain access tokens that expire
                      automatically after 1 hour. All of this is seamless to the
                      patient, however.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/security">
                        <Button color="secondary" size="md">
                          Learn more about Carebit security &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <StyledIllustration src={padlock} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default Feature
